/* src/app.css */
:root {
  --bg-primary: #fff;
  --bg-secondary: #ffffff;
  --bg-translation: #f2f2f2;
  --text-primary: #333333;
  --text-secondary: #444;
  --border-color: #dddddd;
  --accent-color: brown;
  --hover-bg: #ffe9e9;
  --active-bg: #ffd2d2;
}

.dark-mode {
  --bg-primary: #1a1a1a;
  --bg-secondary: #1a1a1a;
  --bg-translation: #2d2d2d;
  --text-primary: #cccccc;
  --text-secondary: #cccccc;
  --border-color: #404040;
  --accent-color: brown;
  --hover-bg: #544444;
  --active-bg: #4d3939;
}

body {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  line-height: 1.6;
}

.app {
  text-align: center;
  padding: 20px;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  min-height: 100vh;
}

.app-header{
  display: flex;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid var(--border-color);
  border-image: linear-gradient(to right, transparent 5%, var(--border-color) 10%, var(--border-color) 85%, transparent 95%) 100% 1;
}

.app-logo {
  height: 22px;
  margin-right: 10px;
}

h1 {
  margin: 0px;
  font-size: 1.2rem;
  color: var(--accent-color);
  flex: 1;
  text-align: left;
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.domain-name {
  color: var(--text-secondary);
  padding-right: 5px;
  font-weight: bold;
}

.locale-switcher {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
}

.locale-switcher .active {
  font-weight: bolder;
  color: var(--text-primary);
  opacity: 1;
}

.locale-switcher button {
  padding: 5px 5px 0px 5px;
  opacity: 0.5;
}

button:focus {
  outline:0;
}

.locale-switcher img {
  height: 20px;
  box-shadow: 0 0 2px #333333aa;
  border-radius: 10%;
}

.translation-form {

  border-radius: 8px;
  max-width: 1300px;
  max-height: 70%;
  margin: 20px auto;
}

.language-selectors {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.language-selectors select {
  display: none;
}

.language-selector {
  flex: 1;
  min-width: 0;
}

.language-selectors .active {
  border-color: var(--accent-color);
  color: var(--text-primary);
}

.recent-languages {
  display: flex;
}

.more-languages-button {
  padding-top: 10px;
  padding-bottom: 0px;
  font-size: 20px;
}

.languages-container {
  position: absolute;
  background-color: var(--bg-secondary);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  z-index: 4;
  padding: 0px;
  overflow: hidden;
  width: 50%;
  border-top-right-radius: 0%;
  border-bottom-right-radius: 0%;
}

.languages-container.tgt {
  right: 0;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 0%;
  border-bottom-left-radius: 0%;
}

.highlight {
  background-color: yellow;
}

.filter-languages {
  padding: 15px;
  width: 100%;
  border: none;
  border-bottom: 1px solid var(--border-color);
  caret-color: var(--accent-color);
  background-color: var(--bg-secondary);
  color: var(--text-primary);
}

.filter-languages::placeholder {
  color: var(--text-secondary);
}

.filter-languages:focus {
  outline: none;

}

.languages-list {
  display: grid;
  grid-template-rows: repeat(9, auto);
  grid-auto-flow: column;
  text-align: left;
  padding: 0px;
}


.language {
  cursor: pointer;
  border-bottom: 2px solid transparent;
}

.languages-list span {
  color: var(--text-secondary);
  font-size: 13px;
}

.languages-list .active span {
  color: var(--text-primary);
}

.languages-list .language {
  padding: 0px 0px 0 15px;
}

.languages-list .language:hover span {
  color: var(--text-primary);
}

/* Theme toggle button styles */
.theme-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  margin-left: 10px;
  border-radius: 50%;
  color: var(--text-primary);
  transition: all 0.3s ease;
}

.theme-toggle:hover {
  background-color: var(--hover-bg);
}

.theme-toggle.dark {
  color: var(--accent-color);
}


.languages-list .active {
  border: 0;
  background-color: var(--active-bg) !important;
}

.languages-list .language:hover {
  background-color: var(--hover-bg);
}

.text-areas {
  display: flex;
  margin-bottom: 20px;
  border-radius: 8px;
  border: 1px solid var(--border-color);
}

.translation, .input-area, .translation-area {
  width: 50%;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  border-radius: 8px;

  background-color: var(--bg-secondary);
}

.input-area {
  position: relative;
}

.incorrect-chars-warning {
  color: rgba(165, 42, 42, 0.6);
  font-size: 15px;
  padding: 12px 20px;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.incorrect-chars-warning:hover {
  color: brown;
}

.variant {
  border-bottom: var(--border-color) 1px solid;
  padding: 0px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
}

.variant:last-child {
  border-bottom: none;
}

.variant:hover {
  background-color: var(--active-bg);
}

.source-text-overlay {
  position: absolute;
  background-color: transparent;
  font: inherit;
  padding: 10px;
  top: 0;
  left: 0;
  width: calc(100% - 20px);
  height: 50%;
  z-Index: 2;
  resize: none;
  white-Space: pre-wrap;
  border: none;
  caret-color: brown;
  color: inherit;
}

.source-text-overlay::placeholder {
  color: rgb(193, 179, 179);
}

textarea:focus {
  outline: none;
}

.source-sentence.highlighted {
  background-color: var(--active-bg);
}

.translated-sentence {
  cursor: pointer;
  white-space: pre-wrap;
  z-index: auto;
  position: relative;
}

.translated-sentence:hover, .show-variants {
  background-color: var(--active-bg);
}

.translation-variants {
  font-size: small;
  position: absolute;
  width: max-content;
  display: flex;
  flex-wrap: wrap;
  background-color: var(--bg-translation);
  flex-direction: column;
  border-radius: 4px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border: 1px solid var(--border-color);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 3;
}

.variant {
  border-bottom: 1px solid var(--border-color);
  padding: 0px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  color: var(--text-primary);
}

.variant:last-child {
  border-bottom: none;
}

.variant:hover {
  background-color: var(--active-bg);
}

button {
  background-color: transparent;
  color:  #999;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 15px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  transition: all 0.3s ease;
}

button:hover {
  color: var(--text-primary);
  box-shadow: none;
}

button:disabled {
  color: transparent;
  cursor: default;
}

.clear-button {
  display: none;
}

.translation-result p {
  word-wrap: break-word;
}

.text-areas {
  position: relative;
}

.source-text, .translation-text {
  min-height: 185px;
  resize: none;
  padding: 10px;
  text-align: left;
}

.source-text {
  color: transparent;
}

.source-text:focus {
  outline: none;
}

.tools-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.tools-area button, .revert-languages-button, .more-button, .clear-button {
  border-radius: 30px;
}

button.clicked {
  background-color: rgba(165, 42, 42, 0.100);
  transform: scale(0.95);
  transition: transform 0.1s ease, background-color 0.2s ease;
}

.translation-area {
  background-color: var(--bg-translation);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.text-areas {
  display: flex;
  flex-direction: row;
}

.input-area, .translation-area {
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
}

.source-text, .translation-text {
  flex-grow: 1; 
}

body {
  overflow: auto;
}

.paragraph {
  position: relative;}

.selected {
  background-color: var(--hover-bg);
}

[contenteditable]:empty:before {
  content:attr(placeholder);
  color:rgb(193, 179, 179);
}

@media (max-width: 800px) {

  .app {
    padding: 0px;
  }

  .app-header{
    display: flex;
    padding: 10px;
    border-bottom: 0;
    border-image: unset; 
    padding-bottom: 20px;
    padding-right: 40px; 
  }

  h1 {
    justify-self: center;
    margin: unset;
    font-size: large;
    margin-bottom: 0px;
    color: brown;
    display: flex;
    justify-content: center;

  }

  body {
    background-color: var(--bg-primary);
  }

  .translation-form {
    margin: 0px;
    padding: 0px;
    border-radius: 0px;
    box-shadow: none;
    background-color: transparent;
  }

  .language-selectors {
    display: flex;
    flex-direction: row;
    padding: 0px; 
    margin-left: 10px;
    margin-right: 10px;
    justify-content: center;
    border-bottom: 1px solid var(--border-color);
    border-image: linear-gradient(to right, transparent 10%, var(--border-color) 25%, var(--border-color) 75%, transparent 90%) 100% 1;  
    align-items: center;
  }

  .language-selectors select {
    display: block;
    align-self: center; 
    padding: 0px; 
    margin: 0px;
    flex-grow: 1;
    min-width: 0px;
    color: var(--text-primary);
    background-color: transparent;
    border: none;
    padding-left: 20px;
    justify-items: start;
    width: 100%;
    font-size: medium;
  }

  .language-selectors .recent-languages {
    display: none;
  }

  .language-selectors select:focus {
    outline: none;
    background-color: transparent;
    color: var(--text-primary);
  }

  .text-areas {
    flex-direction: column;
    align-items: center;
    padding: 0px;
    gap: 0px;
    font-size: x-large; 
    border: none;
  }

  .input-area, .translation-area {
    width: 100%;
    padding: 0px;
    border: unset;
  }

  .input-area {
    height: auto;
    background-color: transparent;
  }

  .translation-area {
    height: auto;
    border-radius: 0px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }

  .source-text {
    min-height: 5vh;
  }

  .translation-text {
    min-height: 5vh;
  }

  button, select {
    -webkit-tap-highlight-color: transparent;
    color: var(--text-primary);
    background-color: transparent;
    border: none;
    cursor: pointer;
  }

  button:hover {
    background-color: transparent;
  }

  button.clicked {
    background-color: rgba(165, 42, 42, 0.100);
    transform: scale(0.95);
    transition: transform 0.1s ease, background-color 0.2s ease;
  }

  .clear-button {
    display: block;
    padding: 10px;
    font-size: large;
  }

  .locale-switcher {
    position: absolute;
    flex-direction: column;
    z-index: 4;
    padding: 10px;
  }
  
  .locale-switcher button {
    display: none;
    order: 1;
  }
  
  .locale-switcher:hover button {
    display: block;
  }

  .locale-switcher:hover {
    background-color: var(--bg-primary);
  }
  
  .locale-switcher .active {
    display: block;
    font-weight: bolder;
    color: #333;
    order: 0;
  }

  .source-text:focus, .translation-text {
    transition: font-size 0.3s ease;
  }

  .source-text:not(:empty), .translation-text:not(:empty) {
    font-size: x-large;
  }

  .source-text.long-text, .translation-text.long-text {
    font-size: medium;
  }

  .source-text.very-long-text, .translation-text.very-long-text {
    font-size: small;
  }

}
.dark-mode .locale-switcher .active {
  opacity: 0.8;
}